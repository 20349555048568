.my-button2 {
  /* padding: 15px 25px; */
  border: unset;
  color: #fff;
  z-index: 1;
  background: #fff;
  position: relative;
  font-weight: 400;
  color: #000;
  border: 1px solid #ff0000;
  transition: all 500ms;
  overflow: hidden;
}

.my-button2:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.my-button2::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0;
  background-color: #ff0000;
  z-index: -1;
  transition: all 500ms;
}


.my-button2:hover {
  color: #fff;
}

.my-button2:hover::before {
  width: 100%;
}
