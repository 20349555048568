@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap');
html {
  scroll-behavior: smooth;
}

.allerta-stencil-regular {
  font-family: "Allerta Stencil", sans-serif;
  font-weight: 400;
  font-style: normal;
}


::-webkit-scrollbar {
  width: 0px;
  background-color: #fff;
}

/* Track */
::-webkit-scrollbar-track {
  /* background: #888; */
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: #555; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root {
  background-color: #ff0000 !important;
  color: white !important;
  font-weight: 600 !important;
}

.swiper-button-next::after {
  color: #ff0000;
}

.swiper-button-prev::after {
  color: #ff0000;
}

.shadow-example {
  /* Birinchi soya */
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    /* Ikkinchi soya */ 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
}


  .loader {
    border: 4px solid transparent;
    border-top-color: white;
    animation: fadeInOut 2s ease-in-out infinite;
  }



  @keyframes fadeInOut {
    0%, 100% {
      opacity: 0.5;
    }
    50% {
      opacity: 1;
    }
  }
